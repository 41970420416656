body {
    margin: 5% 10%;
    font-family: "Segoe UI", sans-serif;
    font-size: 1.2em;
}

.video-wrapper {
    position: relative;
    padding-bottom: 62%;
}

.video-wrapper input[type="checkbox"] {
    position: absolute;
    top: -25px;
    left: 10px;
}

.video-wrapper label {
    position: absolute;
    top: -29px;
    left: 48px;
}

.video-wrapper iframe {
    position: absolute;
    top: 15px;
    left: 0;
    width: 100%;
    height: 90%;
}

input[type="checkbox"] {
    appearance: none;
    outline: none;
    background-color: white;
    border-radius: 50%;
    border: 1px solid gray;
    width: 20px;
    height: 20px;
    position: relative;
    margin-right: 10px;
}

input[type="checkbox"]:checked::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #d889f6;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

h1 {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 1.5em;
    color: #37373d;
    text-align: left;
    text-transform: uppercase;
}

h2 {
    font-size: 36px;
    font-weight: bold;
    margin: 1.8em 0 1.5em;
    color: rebeccapurple;
    text-align: center;
}

.menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

p.menu {
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: #61667c;
    line-height: 1.8em;
}
.space {
    padding-right: 1em;
}
a {
    text-decoration: none;
    color: #61667c;
}
a:hover {
    color: #b6b6e8;
}
a:visited {
    color: rebeccapurple;
}
textarea {
    width: 93%;
    height: 15em;
    margin: 0 0 4em;
    border: 1px solid #adadb1;
    padding: 1em;
    font-family: Arial, san-serif;
    font-size: 1em;
    color: #343434;
}
.scroll-top {
    position: fixed;
    bottom: 20px;
    right: 6px;
    background-color: white;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 99;
}

.scroll-top i {
    font-size: 20px;
    color: #333;
}